@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@mixin rotateAnimation($seconds) {
  animation: rotate360 #{$seconds}s linear infinite;
}

.in-progress-icon {
  @include rotateAnimation(2);
  min-width: 20px;
}

.slow-cell-icon {
  @include rotateAnimation(8);
  min-width: 20px;
}
