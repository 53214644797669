.login-center {
  font-family: Helvetica, Arial, sans-serif;
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 850px;
  position: relative;
}
.login-logo {
  height: 80px;
  width: 80px;
}

.login-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  min-height: -webkit-calc(100% - 60px);
  min-height: -moz-calc(100% - 60px);
  min-height: calc(100% - 60px);
}

.form-item {
  color: #9e9e9e;
  width: 90%;
  margin-bottom: 2rem;
  height: 30px;
  border: none;
  border-bottom: 1px solid #e5e5e5;
}

.form-submit {
  font-family: Helvetica, Arial, sans-serif;
  height: 50px;
  color: white;
  background-color: #66bb66;
  border: none;
  letter-spacing: 0.1rem;
  transition: 0.3s opacity ease;
  cursor: pointer;
  width: 100%;
  position: absolute;
  bottom: 0px;
  font-weight: 600;
}
.form-submit:hover {
  opacity: 0.6;
}
.error-message {
  width: 90%;
  margin-top: 10px;
  height: 24px;
}
